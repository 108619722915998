import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import { Link } from 'gatsby';

import Layout from '../components/Layout';

export const tileImage = graphql`
  fragment tileImage on File {
    childImageSharp {
      fluid(maxWidth: 500, quality: 20, toFormat: JPG) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`;

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      tempo: file(relativePath: { eq: "tempo-studio.png" }) {
        ...tileImage
      }
      gf: file(relativePath: { eq: "gf.png" }) {
        ...tileImage
      }
      ustart: file(relativePath: { eq: "u-start.png" }) {
        ...tileImage
      }
      agathon: file(relativePath: { eq: "agathon-desktop.png" }) {
        ...tileImage
      }
      quartzteq: file(relativePath: { eq: "quartzteq.png" }) {
        ...tileImage
      }
      metrohm: file(relativePath: { eq: "metrohm.png" }) {
        ...tileImage
      }
    }
  `);

  return (
    <Layout>
      <div id="main">
        <div className="inner">
          <header>
            <h1>
              Hi, I'm a software craftsman.
              <br />
            </h1>
            <p>
              I'm focused on clean code, clear communication, and simplicity.
            </p>
          </header>
          <section>
            <ul className="icons">
              <li>
                <a
                  href="/ken_blum_resume.pdf"
                  target="_blank"
                  className="button"
                >
                  Resume
                </a>
              </li>
              <li>
                <a
                  href="https://www.linkedin.com/in/kmblum/"
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                >
                  LinkedIn
                </a>
              </li>
              <li>
                <a
                  href="https://github.com/blumk"
                  target="_blank"
                  rel="noreferrer"
                  className="button"
                >
                  GitHub
                </a>
              </li>
            </ul>
          </section>
          <section className="tiles">
            <article className="style6">
              <span className="image">
                <Img
                  sizes={{
                    ...data.tempo.childImageSharp.fluid,
                    aspectRatio: 3 / 2,
                  }}
                  alt="tempo studio"
                />
              </span>
              <Link to="/tempo">
                <h2>Tempo</h2>
                <div className="content">
                  <p>The home gym that fits into your life</p>
                </div>
              </Link>
            </article>
            <article className="style1">
              <span className="image">
                <Img
                  sizes={{
                    ...data.gf.childImageSharp.fluid,
                    aspectRatio: 3 / 2,
                  }}
                  alt="part inspector"
                />
              </span>
              <Link to="/georg-fischer">
                <h2>Georg Fischer</h2>
                <div className="content">
                  <p>Part Inspector – analyze 3D parts anywhere</p>
                </div>
              </Link>
            </article>
            <article className="style2">
              <span className="image">
                <Img
                  sizes={{
                    ...data.ustart.childImageSharp.fluid,
                    aspectRatio: 3 / 2,
                  }}
                  alt="u-start"
                />
              </span>
              <Link to="/u-blox">
                <h2>u-blox</h2>
                <div className="content">
                  <p>Explorer kit for u-blox M9 standard precision GNSS</p>
                </div>
              </Link>
            </article>
            <article className="style3">
              <span className="image">
                <Img
                  sizes={{
                    ...data.agathon.childImageSharp.fluid,
                    aspectRatio: 3 / 2,
                  }}
                  alt="agathon"
                />
              </span>
              <Link to="/agathon">
                <h2>Agathon</h2>
                <div className="content">
                  <p>
                    Production and status data on the smartphone – in real time
                  </p>
                </div>
              </Link>
            </article>
            <article className="style4">
              <span className="image">
                <Img
                  sizes={{
                    ...data.quartzteq.childImageSharp.fluid,
                    aspectRatio: 3 / 2,
                  }}
                  alt="quartzteq"
                />
              </span>
              <Link to="/quartzteq">
                <h2>Quartzteq</h2>
                <div className="content">
                  <p>PDA II Next Generation Software</p>
                </div>
              </Link>
            </article>
            <article className="style5">
              <span className="image">
                <Img
                  sizes={{
                    ...data.metrohm.childImageSharp.fluid,
                    aspectRatio: 3 / 2,
                  }}
                  alt="metrohm"
                />
              </span>
              <Link to="/metrohm">
                <h2>Metrohm</h2>
                <div className="content">
                  <p>OMNIS – Titration on a whole new level</p>
                </div>
              </Link>
            </article>
            {/* <article className="style3">
            <span className="image">
              <img src={quartzteq} alt="" />
            </span>
            <Link to="/quartzteq">
              <h2>Microsoft Hackathon 2010</h2>
              <div className="content">
                <p>Winning 3 flagship phones</p>
              </div>
            </Link>
          </article>
          <article className="style3">
            <span className="image">
              <img src={quartzteq} alt="" />
            </span>
            <Link to="/quartzteq">
              <h2>Topoguru</h2>
              <div className="content">
                <p>Rock climbing website</p>
              </div>
            </Link>
          </article> */}
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;
